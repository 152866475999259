<!-- <div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <mat-card class="example-card">
      <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
        <h2>Log In</h2>
        <mat-form-field class="example-full-width">
          <label>
            <input matInput placeholder="Username" formControlName="username"
                   [errorStateMatcher]="matcher">
          </label>
          <mat-error>
            <span *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">Please enter Username</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <label>
            <input type="password" matInput placeholder="Password" formControlName="password"
                   [errorStateMatcher]="matcher">
          </label>
          <mat-error>
            <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Please enter Password</span>
          </mat-error>
        </mat-form-field>
        <div class="button-row">
          <button type="submit" [disabled]="!loginForm.valid" mat-flat-button color="primary"><mat-icon>login</mat-icon>Login With Elite</button>
        </div>
        <div class="button-row">
          <h4>Don't have an account?</h4>
        </div>
        <div class="button-row">
          <a mat-flat-button color="primary" [routerLink]="['/register']"><mat-icon>how_to_reg</mat-icon>Register</a>
        </div>
      </form>
    </mat-card>
  </div> -->

  
    <section class="login-block banner-sec"  [ngStyle]="{'background-image': getUrl()}">
      <!-- <img src="assets/img/login.jpg" id="sect1"> -->

     
            <div class="login-sec">
                <div class="col-sm-12 paddingClass"><img src="assets/img/eAMS_Black.png" class="logo_class"></div>
                <h2 class="text-center">Login Now</h2>
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <div class="form-group">
        <label for="exampleInputEmail1" >Username</label>
        <input type="text" class="form-control" maxlength=""
        (keypress)="onlyNumberkeyPress($event)" (paste)="true" placeholder="Username" formControlName="username"
      >
        
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1" >Password</label>
        <input type="password" class="form-control" placeholder="Password" formControlName="password"
       >
      </div>
      
      
        <div class="form-check">
        <label class="form-check-label col-sm-12 paddingClass">
          <input type="checkbox" class="form-check-input">
          <small>Remember Me</small>
        </label>
        <button type="submit" class="btn btn-login float-right">Submit</button>
      </div>
      
    </form>
    <p class="abcdLogin">Don’t have an account?
      
      <a [routerLink]="['/register']">Sign Up</a>
     </p>
    </div>

    </section>
